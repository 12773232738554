// Request Reducer Constants
export const REQUEST_PENDING = "REQUEST_PENDING";
export const REQUEST_FAILURE = "REQUEST_FAILURE";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FORBIDDEN = "REQUEST_FORBIDDEN";
export const REQUEST_UNAUTHORIZED = "REQUEST_UNAUTHORIZED";
export const REQUEST_UNPROCESSED = "REQUEST_UNPROCESSED";

export const EDIT_RECORD = "EDIT_RECORD";

export const RESET_ERROR = "RESET_ERROR";
