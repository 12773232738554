import Query from "utils/Query";

import * as constants from "./constants";

const userLoginRequest = () => {
  return {
    type: constants.USER_LOGIN_REQUEST
  };
};

const userLoginSuccess = () => {
  return {
    type: constants.USER_LOGIN_SUCCESS
  };
};

const userLoginFailure = error => {
  return {
    type: constants.USER_LOGIN_FAILURE,
    payload: error
  };
};

const userLogoutRequest = () => {
  return {
    type: constants.USER_LOGOUT_REQUEST
  };
};

const userLogoutSuccess = () => {
  localStorage.removeItem("user");
  return {
    type: constants.USER_LOGOUT_SUCCESS
  };
};

export const loadUserSuccess = user => {
  localStorage.setItem("user", JSON.stringify(user));
  return {
    type: constants.USER_FETCH_SUCCESS,
    payload: user
  };
};

export const loginUser = (email, password) => async dispatch => {
  dispatch(userLoginRequest());
  try {
    await Query("auth/login").post({ email, password });
    dispatch(userLoginSuccess());
    return dispatch(fetchAuthenticatedUser(true));
  } catch (error) {
    dispatch(
      userLoginFailure("Sorry! credentials you've entered are not valid")
    );
  }
};

export const logoutUser = () => dispatch => {
  dispatch(userLogoutRequest());
  return Query("auth/logout")
    .post()
    .then(json => dispatch(userLogoutSuccess(json)))
    .catch(error => dispatch(userLogoutSuccess(error)));
};

export const fetchAuthenticatedUser = (liveFetch = false) => async dispatch => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : undefined;

  // get user from local storage without fetch
  if (user && !liveFetch) {
    dispatch({
      type: constants.USER_FETCH_SUCCESS,
      payload: user
    });
  } else {
    dispatch(userLoginRequest());
    try {
      const response = await Query("auth/user").get({});
      dispatch(loadUserSuccess(response.data));
    } catch (error) {
      if (error.status === 422) {
        const validation = await error.json();
        dispatch(userLoginFailure(validation.message));
      } else {
        dispatch(userLoginFailure(error.statusText));
      }
    }
  }
};
