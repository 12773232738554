import produce from "immer";

import * as constants from "./constants";

const cachedUserOrNull = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  isAuthenticating: false,
  user: cachedUserOrNull,
  error: null
};

const auth = produce((draft, action) => {
  const { type, payload } = action;

  if (type === constants.USER_LOGIN_REQUEST) {
    draft.user = null;
    draft.error = null;
    draft.isAuthenticating = true;
    return;
  }

  if (
    type === constants.USER_UPDATE_SUCCESS ||
    type === constants.USER_FETCH_SUCCESS
  ) {
    draft.user = payload;
    draft.error = null;
    draft.isAuthenticating = false;
    return;
  }

  if (type === constants.USER_LOGIN_FAILURE) {
    draft.user = null;
    draft.error = payload;
    draft.isAuthenticating = false;
    return;
  }

  if (type === constants.USER_LOGOUT_SUCCESS) {
    draft.user = null;
    draft.error = null;
    draft.isAuthenticating = false;
  }
}, initialState);

export default auth;
