import { combineReducers } from "redux";
import { SET_LOCALE, DEFAULT_LOCALE } from "./constants";

export const locale = (
  state = localStorage.getItem("locale") || DEFAULT_LOCALE,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOCALE:
      return payload.locale;
    default:
      return state;
  }
};

export default combineReducers({ locale });
