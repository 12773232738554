import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";

// Import i18n messages
import { translations } from "./i18n";

// Import redux store
import configureStore from "./redux/store";
import { fetchAuthenticatedUser } from "modules/Auth/actions";

import * as serviceWorker from "./serviceWorker";
import "./index.css";

// Create redux store with history
const initialState = {};
const store = configureStore(initialState);
store.dispatch(fetchAuthenticatedUser());
const MOUNT_NODE = document.getElementById("root");

/**
 * dynamic load LanguageProvider
 */
const loadLanguageProvider = async () => {
  const module = await import("./modules/Settings/LanguageProvider");
  return module.default;
};

/**
 * dynamic load application routes wrapped in react-dom-router
 */
const loadRouter = async () => {
  const module = await import("./modules/Router");
  return module.default;
};

/**
 * a custom render function aware of server side rendering
 * @param {Object} messages translation messages
 */
const render = async (messages) => {
  const Router = await loadRouter();
  const LanguageProvider = await loadLanguageProvider();

  const Root = () => (
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <Router />
      </LanguageProvider>
    </Provider>
  );

  if (MOUNT_NODE.hasChildNodes()) {
    ReactDOM.render(<Root />, MOUNT_NODE);
  } else {
    ReactDOM.render(<Root />, MOUNT_NODE);
  }
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./i18n", "./modules/Router"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translations);
  });
}

render(translations);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// register service workers if app is on production
if (process.env.NODE_ENV === "production") {
  serviceWorker.register();
}
