import Qs from "qs";

const API_URL = process.env.REACT_APP_API_URL;

const Query = (endpoint, url = new URL(`${API_URL}/${endpoint}`)) => {
  const options = {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language":
        localStorage.getItem("locale") || document.documentElement.lang || "en",
      Accept: "application/json"
    }
  };
  // api calls
  const get = async (params = {}) => {
    url.search = Qs.stringify(params, { arrayFormat: "brackets" });
    return fetch(url, options).then(handleJsonResponse);
  };

  const remove = async (params = {}) => {
    url.search = Qs.stringify(params, { arrayFormat: "brackets" });
    options.method = "DELETE";
    return fetch(url, options).then(handleJsonResponse);
  };

  const post = async (params = {}) => {
    options.method = "POST";
    options.body = JSON.stringify(params);
    return fetch(url, options).then(handleJsonResponse);
  };

  const update = async (params = {}) => {
    options.method = "PUT";
    options.body = JSON.stringify(params);
    return fetch(url, options).then(handleJsonResponse);
  };

  const download = async (params = {}) => {
    url.search = Qs.stringify(params, { arrayFormat: "brackets" });
    return fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "plain/text"
      }
    }).then(handleBlobResponse);
  };

  const upload = async (formData) => {
    return fetch(url, {
      credentials: "include",
      method: "POST",
      body: formData
    }).then(handleJsonResponse);
  };

  const handleJsonResponse = async (response) => {
    if (response.ok) {
      return response.json();
    } else if (response.status === 422) {
      const error = {
        status: response.status,
        message: response.statusText
      };
      const { errors } = await response.json();
      error.validations = errors;
      return Promise.reject(error);
    } else {
      const error = {
        status: response.status,
        message: response.statusText
      };
      return Promise.reject(error);
    }
  };

  const handleBlobResponse = async (response) => {
    if (response.ok) {
      return response.blob();
    } else if (response.status === 422) {
      const error = {
        status: response.status,
        message: response.statusText
      };
      const { errors } = await response.json();
      error.validations = errors;
      return Promise.reject(error);
    } else {
      const error = {
        status: response.status,
        message: response.statusText
      };
      return Promise.reject(error);
    }
  };

  return {
    get,
    post,
    update,
    delete: remove,
    download,
    upload
  };
};

export default Query;
