import { combineReducers } from "redux";

import auth from "modules/Auth/reducers";
import settings from "modules/Settings/reducers";
import request from "modules/Request/reducers";

const AppReducers = combineReducers({
  /* app’s top-level reducers */
  settings,
  request,
  auth
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT_SUCCESS") {
    state = undefined;
  }
  return AppReducers(state, action);
};

export default rootReducer;
