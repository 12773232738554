import produce from "immer";

import {
  REQUEST_PENDING,
  REQUEST_FAILURE,
  REQUEST_SUCCESS,
  REQUEST_FORBIDDEN,
  REQUEST_UNAUTHORIZED,
  REQUEST_UNPROCESSED,
  EDIT_RECORD
} from "./constants";

export const request = produce((draft, action) => {
  const { type, payload } = action;
  // handles a request start action
  if (type === REQUEST_PENDING) {
    draft[payload.id] = {
      pending: true,
      error: null,
      params: payload.params
    };
    return;
  }

  // handles a request success action
  if (type === REQUEST_SUCCESS) {
    draft[payload.id] = {
      pending: false,
      error: null,
      data: payload.data
    };
    return;
  }

  // handles a request failure.
  if (
    type === REQUEST_FAILURE ||
    type === REQUEST_UNPROCESSED ||
    type === REQUEST_FORBIDDEN ||
    type === REQUEST_UNAUTHORIZED
  ) {
    draft[payload.id] = {
      pending: false,
      error: payload.error
    };
  }
  if (type === EDIT_RECORD) {
    const index = draft[payload.id].data.findIndex(
      (obj) => obj.id === payload.record.id
    );
    draft[payload.id].data[index] = payload.record;
  }
}, {});

export default request;
